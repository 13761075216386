import { useLazyQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import {
  IdQueryQuery,
  IdQueryQueryVariables,
  ParagraphSearchByIdFragment,
} from '../../../generated/types';
import { IDQuery } from '../../../queries';
import getAppConfig from '../../../utils/getAppConfig';

interface SearchByIdProps {
  data: ParagraphSearchByIdFragment;
}

interface FormData {
  query: string;
}

const SearchById: React.FC<SearchByIdProps> = ({ data }) => {
  const router = useRouter();
  const [getResult, { loading }] = useLazyQuery<
    IdQueryQuery,
    IdQueryQueryVariables
  >(IDQuery);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    const appConfig = getAppConfig();
    const resultData = await getResult({
      variables: {
        id: parseInt(values.query, 10),
        frontend: appConfig.config.frontendName,
      },
    });

    if (!resultData.data?.idQuery) {
      setError('query', {
        message: 'ID wurde nicht gefunden.',
      });
    } else {
      router.push(resultData.data.idQuery);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl flex={1} isInvalid={!!errors.query} isRequired>
          <FormLabel htmlFor="query">ID</FormLabel>
          <Flex alignItems="center">
            <Input
              bg="white"
              id="query"
              placeholder="Nach ID suchen..."
              {...register('query', {
                required: true,
              })}
            />
            <Button ml={4} mt={0} isLoading={isSubmitting} type="submit">
              Suchen
            </Button>
          </Flex>
          <FormErrorMessage>{errors.query?.message}</FormErrorMessage>
        </FormControl>
      </form>
    </Box>
  );
};

export default SearchById;
